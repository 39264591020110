import React from 'react';

function License() {
  return (
    <div className="container mx-auto px-4 py-20">
      <h1 className="text-4xl font-bold mb-8">Directory Maker License Agreement</h1>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">1. Introduction</h2>
      <p className="mb-4">
        This License Agreement ("Agreement") is a legal agreement between you ("Licensee") and the creators of Directory Maker ("Licensor"). By purchasing, downloading, or using Directory Maker ("Software"), you agree to be bound by the terms and conditions of this Agreement. If you do not agree to the terms of this Agreement, do not use the Software.
      </p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">2. License Types and Grants</h2>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">2.1 Standard License</h3>
        <p className="mb-4">
          <strong>Single-Use License:</strong> The Standard License grants the Licensee a non-exclusive, non-transferable, single-use license to use Directory Maker for one personal or commercial project. The Licensee may install and use the Software on a single website or project.
        </p>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">2.2 Agency License</h3>
        <p className="mb-4">
          <strong>Multi-Project License:</strong> The Agency License grants the Licensee a non-exclusive, non-transferable license to use Directory Maker for multiple client projects. This license allows:
          <ul className="list-disc ml-8 mt-2">
            <li>Use in unlimited client projects</li>
            <li>White-label rights</li>
            <li>Custom branding for each implementation</li>
            <li>Creation of multiple instances for different clients</li>
          </ul>
        </p>
      </div>
      <p className="mb-4">
        <strong>Modifications:</strong> Under both license types, the Licensee may modify or customize the Software to fit their specific needs. However, any derivative works based on the Software are subject to the terms of this Agreement.
      </p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">3. Restrictions</h2>
      <p className="mb-4">
        <strong>No Resale or Redistribution:</strong> The Licensee may not resell, redistribute, or share the Software or any derivative works in its original or modified form. This includes public repositories, marketplaces, or any form of distribution where the Software is accessible to others.
      </p>
      <p className="mb-4">
        <strong>No Sub-licensing:</strong> The Licensee may not sublicense, assign, or transfer the rights granted under this Agreement to any third party without the prior written consent of the Licensor.
      </p>
      <p className="mb-4">
        <strong>Agency License Restrictions:</strong> While the Agency License permits use in multiple client projects, each implementation must be managed by the Agency (Licensee). Clients cannot be given direct access to the Software's source code or be granted licensing rights.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">4. Intellectual Property Rights</h2>
      <p className="mb-4">
        <strong>Ownership:</strong> The Software and all associated intellectual property rights remain the property of the Licensor. The Licensee acknowledges that no ownership rights are transferred under this Agreement.
      </p>
      <p className="mb-4">
        <strong>Trademarks:</strong> Any logos, trademarks, or service marks associated with the Software are the property of their respective owners. The Licensee may not use these trademarks without the express written consent of the trademark owner.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">5. Limitation of Liability</h2>
      <p className="mb-4">
        <strong>Disclaimer:</strong> The Software is provided "as is" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The Licensor does not warrant that the Software will be error-free, secure, or meet the Licensee's specific requirements.
      </p>
      <p className="mb-4">
        <strong>Limitation of Liability:</strong> In no event shall the Licensor be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the Licensee's use of the Software, whether based on warranty, contract, tort, or any other legal theory, even if the Licensor has been advised of the possibility of such damages.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">6. Termination</h2>
      <p className="mb-4">
        <strong>Termination for Breach:</strong> The Licensor reserves the right to terminate this Agreement and the Licensee's access to the Software if the Licensee violates any of the terms of this Agreement.
      </p>
      <p className="mb-4">
        <strong>Effect of Termination:</strong> Upon termination, the Licensee must cease all use of the Software and destroy any copies in their possession. The Licensee will not be entitled to a refund or compensation of any kind upon termination.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">7. Refund Policy</h2>
      <p className="mb-4">
        <strong>No Refunds:</strong> Due to the nature of digital products, all sales of the Software are final. The Licensor does not offer refunds under any circumstances. The Licensee is responsible for reviewing all product details, demos, and documentation prior to purchase to ensure that the Software meets their needs.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">8. Feature Requests and Support</h2>
      <p className="mb-4">
        <strong>Feature Requests:</strong> While the Licensor welcomes feedback and suggestions for future updates, custom feature requests are not accepted. The Licensor may, at its discretion, incorporate user suggestions into future versions of the Software.
      </p>
      <p className="mb-4">
        <strong>Support:</strong> The Licensor provides support for issues related to the functionality of the Software. However, the Licensor does not offer customization services or work directly on Licensee's projects. The Licensee is responsible for implementing and customizing the Software for their specific use case.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">9. Updates and Maintenance</h2>
      <p className="mb-4">
        <strong>Software Updates:</strong> The Licensor may provide updates to the Software from time to time. These updates are provided at the Licensor's discretion and may include bug fixes, new features, or other improvements.
      </p>
      <p className="mb-4">
        <strong>Maintenance:</strong> The Licensor is not obligated to provide maintenance or support services for the Software. However, the Licensor may offer maintenance services at its discretion.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">10. Governing Law</h2>
      <p className="mb-4">
        <strong>Jurisdiction:</strong> This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction in which the Licensor is based, without regard to its conflict of law principles.
      </p>
      <p className="mb-4">
        <strong>Dispute Resolution:</strong> Any disputes arising out of or in connection with this Agreement shall be resolved through negotiations in good faith. If negotiations fail, the dispute shall be submitted to binding arbitration in the jurisdiction of the Licensor.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">11. Entire Agreement</h2>
      <p className="mb-4">
        This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter. No amendment or modification of this Agreement shall be binding unless in writing and signed by the Licensor.
      </p>
    </div>
  );
}

export default License;

